/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/index.scss"

// force update without alert
export const onServiceWorkerUpdateReady = () => window.location.reload(true)

// export const onServiceWorkerUpdateFound = () => {
//   if (
//     window.confirm(
//       "This site has been updated with new data. Do you wish to reload the site to get the new data?"
//     )
//   ) {
//     window.location.reload(true)
//   }
// }
